window.onload = function(){
  const anim_image = document.querySelectorAll('.animate_image');
  anim_image.forEach(elem=>animate(elem, '.animate_image'));
  const anim_cont = document.querySelectorAll('.animate_container');
  anim_cont.forEach(elem=>animate(elem, '.animate_container'));
  const product_1 = document.querySelectorAll('.animate_product1');
  product_1.forEach(elem=>animate(elem, '.animate_product1'));
  const product_2 = document.querySelectorAll('.animate_product2');
  product_2.forEach(elem=>animate(elem, '.animate_product2'));
  const product_3 = document.querySelectorAll('.animate_product3');
  product_3.forEach(elem=>animate(elem, '.animate_product3'));
}

function animate(element, selector){
  element.classList.remove('animate_show');
  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        element.classList.add('animate_show');
        return;
      }
    });
  });
  observer.observe(document.querySelector(selector));
}
